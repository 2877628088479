import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Heading from "../components/common/heading"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import "./error-404.scss"
import Slider from "react-slick"
import { StaticImage } from "gatsby-plugin-image"
import queryString, { parse } from "query-string"

const ThankYouPage = ({ pageContext }) => {
  const intl = useIntl()
  const isSSR = typeof window === "undefined"

  let parsed, personName, isNewsletter
  if (!isSSR) {
    parsed = queryString.parse(window.location.search)
    personName = parsed?.name ? parsed.name : ""
    isNewsletter = parsed?.titre === "Newsletter" ? 1 : 0
  }

  const seoData = setSeoData({
    lang: intl.locale,
    title: intl.formatMessage({ id: "thank_you.title" }),
    field_seo: {},
  })

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    lazyLoad: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const Content = () => {
    return (
      <>
        {intl.locale === "en" ? (
          <>
            {personName && <p>Dear {personName},</p>}
            <p>
              Thank you for your request through our website and interest
              in Bel Ombre. A member of our team will contact you within
              24 hours.
            </p>
            <p>
              In the meantime, please join us on Facebook and feel free to
              ask us any question there:
            </p>
            <p>
              <a
                href="https://www.facebook.com/BelOmbreMRU"
                target="_blank"
              >
                Bel Ombre, Mauritius
              </a>
            </p>
            <p>You may also follow us on Instagram:</p>
            <p>
              <a
                href="https://www.instagram.com/belombremauritius/"
                target="_blank"
              >
                https://www.instagram.com/belombremauritius/
              </a>
            </p>
            <p>
              Your complete satisfaction is our top priority. If you have
              question, concerns, or ideas, please don't hesitate to
              contact us. Our Customer Service can be reached via email at{" "}
              <a href="mailto:info@belombre.com">info@belombre.com</a>.
            </p>
            <p>
              Please let us know if there is any other way we can help.
            </p>
            <p>Best Regards</p>
            <p>Bel Ombre Team</p>
          </>
        ) : (
          <>
            {personName && <p>Cher(e) {personName},</p>}
            <p>
              Nous vous remercions de votre demande sur notre site Web et
              de votre intérêt pour Bel Ombre. Un membre de notre équipe
              communiquera avec vous dans les 24 heures.
            </p>
            <p>
              Entre-temps, joignez-vous à nous sur Facebook et n’hésitez
              pas à nous poser vos questions :
            </p>
            <p>
              <a
                href="https://www.facebook.com/BelOmbreMRU"
                target="_blank"
              >
                Bel Ombre, Mauritius
              </a>
            </p>
            <p>Vous pouvez également nous suivre sur Instagram :</p>
            <p>
              <a
                href="https://www.instagram.com/belombremauritius/"
                target="_blank"
              >
                https://www.instagram.com/belombremauritius/
              </a>
            </p>
            <p>
              Votre satisfaction est notre priorité absolue. Si vous avez
              des questions, des préoccupations ou des idées, n’hésitez
              pas à communiquer avec nous. Vous pouvez communiquer avec
              notre service à la clientèle par courriel à{" "}
              <a href="mailto:info@belombre.com">info@belombre.com</a>.
            </p>
            <p>Dites-nous s’il y a d’autres façons de vous aider.</p>
            <p>Cordialement</p>
            <p>L'équipe de Bel Ombre</p>
          </>
        )}
      </>
    )
  }

  const ContentNewsletter = () => {
    return (
      <>
        {intl.locale === "en" ? (
          <>
            <p>
              Thank you for signing up and your interest in Bel Ombre. <br />
              You have successfully subscribed to our newsletter featuring Bel Ombre news and special offers.
            </p>
            <p>
              In the meantime, please join us on Facebook and feel free to ask us any question there:
            </p>
            <p>
              <a
                href="https://www.facebook.com/BelOmbreMRU"
                target="_blank"
              >
                Bel Ombre, Mauritius
              </a>
            </p>
            <p>You may also follow us on Instagram:</p>
            <p>
              <a
                href="https://www.instagram.com/belombremauritius/"
                target="_blank"
              >
                https://www.instagram.com/belombremauritius/
              </a>
            </p>
            <p>
              Your complete satisfaction is our top priority. If you have
              question, concerns, or ideas, please don't hesitate to
              contact us. Our Customer Service can be reached via email at{" "}
              <a href="mailto:info@belombre.com">info@belombre.com</a>.
            </p>
            <p>
              Please let us know if there is any other way we can help.
            </p>
            <p>Best Regards</p>
            <p>Bel Ombre Team</p>
          </>
        ) : (
          <>
            <p>
              Merci de votre inscription et de votre intérêt pour Bel Ombre. <br />
              Vous vous êtes abonné avec succès à notre newsletter contenant les actualités et les offres spéciales de Bel Ombre.
            </p>
            <p>
              Entre-temps, joignez-vous à nous sur Facebook et n’hésitez
              pas à nous poser vos questions :
            </p>
            <p>
              <a
                href="https://www.facebook.com/BelOmbreMRU"
                target="_blank"
              >
                Bel Ombre, Mauritius
              </a>
            </p>
            <p>Vous pouvez également nous suivre sur Instagram :</p>
            <p>
              <a
                href="https://www.instagram.com/belombremauritius/"
                target="_blank"
              >
                https://www.instagram.com/belombremauritius/
              </a>
            </p>
            <p>
              Votre satisfaction est notre priorité absolue. Si vous avez
              des questions, des préoccupations ou des idées, n’hésitez
              pas à communiquer avec nous. Vous pouvez communiquer avec
              notre service à la clientèle par courriel à{" "}
              <a href="mailto:info@belombre.com">info@belombre.com</a>.
            </p>
            <p>Dites-nous s’il y a d’autres façons de vous aider.</p>
            <p>Cordialement</p>
            <p>L'équipe de Bel Ombre</p>
          </>
        )}
      </>
    )
  }

  const ThankYouContent = () => {
    if (isNewsletter) {
      return <ContentNewsletter />;
    }
    return <Content />;
  }

  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} />
      <section className="hero custom-dots height-small">
        <Slider {...settings}>
          <div>
            <div className="hero-element">
              <StaticImage
                className="wideBanner"
                src="../components/common/staticHero/contact-bel-ombre-territory.webp"
                width="1920"
                height="600"
              />
              <Container className="hero-contents">
                <Row className="justify-content-center alignCenter">
                  <Col md={10}>
                    <Heading
                      title={intl.formatMessage({ id: "thank_you.title" })}
                      fontStyle={1}
                      description={intl.formatMessage({
                        id: "thank_you.description",
                      })}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Slider>
      </section>
      <section className="content-thank-you pt-50 pb-50">
        <Container>
          <Row>
            <Col>
              <ThankYouContent />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ThankYouPage
